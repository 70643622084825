<script lang="ts">
    import { inertia } from '@inertiajs/svelte';
    import { onMount } from 'svelte';
    import HomeIcon from '@/Icons/HomeIcon.svelte';
    import ExploreGridIcon from '@/Icons/ExploreGridIcon.svelte';
    import ExploreShortsIcon from '@/Icons/ExploreShortsIcon.svelte';

    export let authUser;

    console.log('Auth User', authUser);

    let currentRoute: string = '';
    onMount(() => {
        currentRoute = window.location.pathname;
    });

    $: isAuthenticated = !!authUser;
    $: feedRoute = isAuthenticated ? '/feed' : '/';
</script>

<div class="m-3 flex w-full items-center justify-around rounded-md bg-gray-50 p-2 dark:bg-neutral-800 md:hidden">
    <div>
        <a href="{feedRoute}" class="h-pill h-pill-primary nav-link flex justify-between px-3">
            <div class="flex items-center justify-center">
                <div class="icon-wrapper flex items-center justify-center">
                    <HomeIcon type="{currentRoute === '/feed' ? 'solid' : 'outline'}" class="h-7 w-7" />
                </div>
            </div>
        </a>
    </div>
    <div>
        <a href="/explore/grid" use:inertia class="h-pill h-pill-primary nav-link flex justify-between px-3">
            <div class="flex items-center justify-center">
                <div class="icon-wrapper flex items-center justify-center">
                    <ExploreGridIcon type="{currentRoute === '/explore/grid' ? 'solid' : 'outline'}" class="h-7 w-7" />
                </div>
            </div>
        </a>
    </div>
    <div>
        <a href="/explore/shorts" use:inertia class="h-pill h-pill-primary nav-link flex justify-between px-3">
            <div class="flex items-center justify-center">
                <div class="icon-wrapper flex items-center justify-center">
                    <ExploreShortsIcon
                        type="{currentRoute === '/explore/shorts' ? 'solid' : 'outline'}"
                        class="h-7 w-7"
                    />
                </div>
            </div>
        </a>
    </div>
</div>

<div class="inline-border-tabs mb-1 mt-2 hidden md:block">
    <nav class="nav nav-pills nav-justified text-bold m-3">
        <a
            class="nav-item nav-link {currentRoute === '/explore/grid' ? 'active' : ''}"
            use:inertia
            href="/explore/grid"
        >
            Explore
        </a>
        <a
            class="nav-item nav-link {currentRoute === '/explore/shorts' ? 'active' : ''}"
            use:inertia
            href="/explore/shorts"
        >
            Shorts
        </a>
    </nav>
</div>
